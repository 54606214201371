footer {
    height: 80px;
    bottom: 0;
    left: 0;
    position: relative;
    width: 100%;
    background-color: rgb(215, 210, 200);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-left {
    margin-left: 20px;
}

footer img {
    height: 100%;
}