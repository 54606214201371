html {
  font-size: 12pt;
}

/* @media (max-width: 800px) {
  html {
    font-size: 12pt;
  }
} */

body {
  text-align: center;
  margin: 0;
  font-family: 'Georgia';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf8f4ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 80px;
}

.content {
  margin-top: 80px;
  /* Same as header height */
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 80px);
}

.text-big {
  font-family: 'Pinyon Script';
  font-size: 4rem;
  margin: 5px;
}

.text-medium {
  margin: 20px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@font-face {
  font-family: 'Pinyon Script';
  src: url('./assets/fonts/PinyonScript-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}