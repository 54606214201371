header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #faf8f4ff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.3s ease-out;
    z-index: 999;
}

.dropdown-menu {
    padding-top: 20px;
    position: fixed;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #faf8f4ff;
    display: flex;
    flex-direction: column;
    justify-content: left;
    text-align: left;
    align-items: left;
    z-index: 888;
}

.hidden {
    transform: translateY(-100%);
}

.header-left {
    margin-left: 20px;
    margin-top: 10px;
    font-family: 'Pinyon Script';
    font-size: 3.0rem;
}

.header-right {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    font-size: 1.3rem;
}

header a {
    text-decoration: none;
}

.dropdown-menu a {
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    font-size: 1.2rem;
}

.header-right a {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
}

.header-right a:hover {
    background-color: rgb(215, 210, 200);
}