.login-page {
    margin-top: 25vh;
    font-size: x-large;
}

.password {
    margin-left: 8px;
    margin-bottom: 0px;
}

input {
    margin-top: 5px;
    font-size: x-large;
    size: 15;
    width: 200px;
    height: 25px;
}

button {
    margin-left: 5px;
    margin-top: 5px;
    width: 100px;
    font-size: x-large;
    text-align: center;
}

.error-msg {
    color: red;
    font-weight: bold;
    font-size: small;
    margin-top: 5px;
}