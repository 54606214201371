button {
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 20px;
    border-width: 0;
    font-size: 1.2rem;
    background: none;
    width: 200px;
    background-color: rgb(215, 210, 200);
    margin-bottom: 20px;
}

.fixed-width-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    justify-content: top;
    align-items: center;
    margin-bottom: 20px;
}

h2 {
    font-family: 'Pinyon Script';
    font-size: 2rem;
    padding-top: 20px;
}