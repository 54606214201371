.profiles-mobile {
    display: flex;
    flex-direction: column;
    margin: 20px;
    justify-content: top;
    align-items: center;
    text-align: left;
    max-width: 90%;
}

.fixed-width-center {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    justify-content: top;
    align-items: center;
    margin-bottom: 20px;
}

.one-third-image {
    margin-top: 40px;
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    max-height: 300vh;
}

.image-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(215, 210, 200);
}

.profiles-mobile .image-wrapper {
    margin-top: 30px;
}

.image-wrapper img {
    border-radius: 0%;
    width: 75px;
    height: 75px;
}

.two-thirds-text-left,
.two-thirds-text-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: left;
    text-align: justify;
    margin-right: 20px;
    max-width: 75%;
}

.two-thirds-text-right {
    margin-right: 10px;
    margin-left: 20px;
}

a {
    margin-top: 20px;
    text-decoration: none;
    text-align: left;
}

.two-thirds-text-left {
    margin-right: 20px;
    margin-left: 10px;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
}

.profiles-mobile h2 {
    margin-top: 20px;
    text-align: center;
}

.profiles-mobile img {
    margin-top: 20px;
}

.consecutive-links {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
}

.consecutive-links a {
    margin: 2px;
}

.seating {
    width: 90%;
    margin-top: 10px;
    padding: 10px;
    border-style: dashed;
    border-color: lightgray;
    color: gray;
}

.sides {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    border: 1px;
}

.side {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.bench {
    font-size: 0.9rem;
    margin: 5px 0;
    text-align: center;
    font-style: italic;
}

.altar {
    font-size: 0.9rem;
    text-align: center;
    font-style: italic;
}