.profiles-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: top;
  align-items: center;
  text-align: justify;
}

.fixed-width-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  justify-content: top;
  align-items: center;
}

.one-third-image {
  margin-top: 40px;
  width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  max-height: 30vh;
}

.two-thirds-text-left,
.two-thirds-text-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: left;
  text-align: justify;
  margin-right: 20px;
}

.two-thirds-text-right {
  margin-right: 10px;
  margin-left: 20px;
}

a {
  margin-top: 20px;
  text-decoration: none;
  text-align: left;
}

.two-thirds-text-left {
  margin-right: 20px;
  margin-left: 10px;
}

.one-third-image img, 
.profiles-mobile img {
  object-fit: cover;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.profiles-mobile h2 {
  margin-top: 20px;
}

.profiles-mobile img {
margin-top: 20px;
}