.image-container img {
    display: flex;
    height: 100vh;
    max-width: 33vw;
    object-fit: cover;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.image-container {
    background-color: rgb(214, 210, 201);
}

.text {
    margin-top: 100px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.text-medium img {
    height: 2rem;
    margin: 10px;
}

a {
    margin: 10px;
    color: inherit;
    text-decoration: underline;
    font-style: normal;
}

.floor-link {
    bottom: 0;
    margin-top: auto;
    padding-top: 50px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: italic;
}

hr {
    border: 0;
    height: 2px;
    background-color: #333;
    margin: 20px 0;
}