.hamburger {
    margin-left: 5px;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    margin: 5px;
    border-radius: 10px;
    background-color: gray;
    transition: 0.5s;
}

.hamburger-open .bar1 {
    transform: translate(0, 11px);
    rotate: 45deg;
}

.hamburger-open .bar2 {
    opacity: 0;
}

.hamburger-open .bar3 {
    transform: translate(0, -11px);
    rotate: -45deg;
}